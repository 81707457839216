.App {
    text-align: center;
    height: 100%;
    margin: 0;
}

.App-link {
    color: #61dafb;
}

/* Folded corner */

.ss-style-foldedcorner::before,
.ss-style-foldedcorner::after {
    bottom: 0;
    width: 100px;
    height: 100px;
}

.ss-style-foldedcorner::before {
    right: 0;
    background-image: -webkit-linear-gradient(top left, #37a2ea 50%, #3498db 50%);
    background-image: linear-gradient(315deg, #3498db 50%, #37a2ea 50%);
}

.ss-style-foldedcorner::after {
    right: 100px;
    background-image: -webkit-linear-gradient(top left, transparent 50%, #236fa1 50%);
    background-image: linear-gradient(315deg, #236fa1 50%, transparent 50%);
}
